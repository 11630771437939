/* eslint-disable react/jsx-key */
import React, { useContext, useEffect, useState } from "react";
import {
  popbanner,
  sd_dashboard_accomadation,
  sd_dashboard_donation,
  sd_dashboard_seva,
  sd_dashboard_side_arrow,
  sd_dashboard_srisaila_prabha,
  sd_dashboard_upcoming,
  sd_news_list_icon,
  SD_Right_Arrow_white,
  sd_script,
} from "../../Assets/imageList";
// import OwlCarousel from "react-owl-carousel";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";
import "../../Utils/sd-styles/sd-css/main.css";
import { SD_Context } from "../../context/sd-context";
import Sd_DashboardService from "../../services/sd-dashboard.service";
import Sd_loading from "../../Components/sd-common-components/sd-loading";
import IndiaTime from "../../Utils/sd-moment/IndiaTime";
import { withRouter } from "react-router";
import Slider from "react-slick";
import { EncryptInformation } from "../../Utils/sd-encrypt/encrypt";
import moment from "moment";
import { Lightbox } from "react-modal-image";
const Sd_dashboard_devotee_dashboard = ({ history }) => {
  //-------------------------------------------------------------states--------------------------------------------------------//
  const { setActive, setActivePath, numFormatter, logout, userData } =
    useContext(SD_Context);
  const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];
  const [selectedLanguage, setSelectedlanguage] = React.useState("en-in");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState();
  // const [hoverActive, setHoverActive] = useState("none");
  const [statisctics, setStatictics] = useState([]);
  const [modal, setModal] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const settings = {
    dots: true,
    autoplay: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    nav: false,
    autoplaySpeed: 15000,
    arrows: false,
  };
  //-------------------------------------------------------------states--------------------------------------------------------//

  //-----------------------------------------------------------useeffect------------------------------------------------------//
  useEffect(() => {
    setLoading(true);
    setActive("devotee-dashboard");
    setActivePath("Dashboard");
    if (
      supportedLanguages.includes(
        history?.location?.pathname?.split?.("/")?.[1]
      )
    ) {
      setSelectedlanguage(history?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedlanguage("en-in");
    }
    getData();
  }, []);
  useEffect(() => {
    if (userData?.email === "") {
      setModal(true);
    }
  }, []);
  //------------------------------------------------------------useffect--------------------------------------------------------//

  //------------------------------------------------------------functions----------------------------------------------------------//

  const getData = async () => {
    try {
      // let res = await Sd_DashboardService.upcoming_booking(4, 1);
      let res = await Sd_DashboardService.booking_history(
        20,
        1,
        process.env.REACT_APP_TEMPLE ?? "SDMSD",
        true,
        "success"
      );
      // let res1 = await Sd_DashboardService.getStatistics();

      setStatictics([]);
      setData(res.items);
      setLoading(false);
    } catch (e) {
      if (e.message === "Session Expired") {
        logout();
        history?.push(
          `/${selectedLanguage}/devotee/signin?to=devotee-app/devotee-dashboard`
        );
      }
      setLoading(false);
    }
  };
  //-------------------------------------------------------------functions--------------------------------------------------------//
  return (
    <div className="col-xs-12 col-sm-12 col-md-9 col-lg-9 sd-dashboard">
      {loading ? (
        <Sd_loading />
      ) : (
        <>
          <h2 className="sd-side-heading fw500">
            <span className="fw700">Devotee</span> Dashboard
          </h2>
          {/* <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 pl-0 pr-0 sd-dd-boxes">
            <div className="sd-dd-box cc-1">
              <div
                className="sd-dd-box-one"
                onMouseLeave={() => {
                  setHoverActive("none");
                }}
              >
                <img src={sd_d_one} alt="" />
                <img
                  src={sd_dots_one}
                  alt=""
                  className="sd-dd-spcl"
                  onMouseEnter={() => {
                    setHoverActive("hover1");
                  }}
                />
                <div
                  className={
                    hoverActive === "hover1"
                      ? "sd-dd-hidden"
                      : "sd-dd-hidden sd-hide"
                  }
                  onMouseLeave={() => {
                    setHoverActive("none");
                  }}
                >
                  <div
                    onClick={() => {
                      setHoverActive("none");
                    }}
                  >
                    All
                  </div>
                </div>
              </div>
              <h3 className="color-1">Pratyaksha Seva</h3>
              <h2 className="color-1">
                {statisctics?.find((e) => e.bookingType === "accomodation")
                  ?.count
                  ? statisctics?.find((e) => e.bookingType === "accomodation")
                      ?.count
                  : 0}
              </h2>
              <p className="color-1">Total Sevas Booked</p>
            </div>
            <div
              className="sd-dd-box cc-2"
              onMouseLeave={() => {
                setHoverActive("none");
              }}
            >
              <div className="sd-dd-box-one">
                <img src={sd_d_two} alt="" />
                <img
                  src={sd_dots_two}
                  alt=""
                  className="sd-dd-spcl"
                  onClick={() => {
                    setHoverActive("hover2");
                  }}
                />
                <div
                  className={
                    hoverActive === "hover2"
                      ? "sd-dd-hidden"
                      : "sd-dd-hidden sd-hide"
                  }
                >
                  <div
                    onClick={() => {
                      setHoverActive("none");
                    }}
                  >
                    All
                  </div>
                </div>
              </div>
              <h3 className="color-2">Paroksha Seva </h3>
              <h2 className="color-2">
                {statisctics?.find((e) => e.bookingType === "parokshaSeva")
                  ?.count
                  ? statisctics?.find((e) => e.bookingType === "parokshaSeva")
                      ?.count
                  : 0}
              </h2>
              <p className="color-2">Total Sevas Booked</p>
            </div>
            <div
              className="sd-dd-box cc-3"
              onMouseLeave={() => {
                setHoverActive("none");
              }}
            >
              <div className="sd-dd-box-one">
                <img src={sd_d_three} alt="" />
                <img
                  src={sd_dots_three}
                  alt=""
                  className="sd-dd-spcl"
                  onClick={() => {
                    setHoverActive("hover3");
                  }}
                />
                <div
                  className={
                    hoverActive === "hover3"
                      ? "sd-dd-hidden"
                      : "sd-dd-hidden sd-hide"
                  }
                >
                  <div
                    onClick={() => {
                      setHoverActive("none");
                    }}
                  >
                    All
                  </div>
                </div>
              </div>
              <h3 className="color-3">Darshanam</h3>
              <h2 className="color-3">
                {statisctics?.find((e) => e.bookingType === "darshanam")?.count
                  ? statisctics?.find((e) => e.bookingType === "darshanam")
                      ?.count
                  : 0}
              </h2>
              <p className="color-3">Total Darshanams</p>
            </div>
            <div
              className="sd-dd-box cc-4"
              onMouseLeave={() => {
                setHoverActive("none");
              }}
            >
              <div className="sd-dd-box-one">
                <img src={sd_d_four} alt="" />
                <img
                  src={sd_dots_four}
                  alt=""
                  className="sd-dd-spcl"
                  onClick={() => {
                    setHoverActive("hover4");
                  }}
                />
                <div
                  className={
                    hoverActive === "hover4"
                      ? "sd-dd-hidden"
                      : "sd-dd-hidden sd-hide"
                  }
                >
                  <div
                    onClick={() => {
                      setHoverActive("none");
                    }}
                  >
                    All
                  </div>
                </div>
              </div>
              <h3 className="color-4">Donations</h3>
              <h2 className="color-4">
                Rs.
                <span className="ml-5">
                  {statisctics?.find((e) => e.bookingType === "donation")
                    ?.amount
                    ? numFormatter(
                        statisctics?.find((e) => e.bookingType === "donation")
                          ?.amount
                      )
                    : "0"}
                </span>
              </h2>
              <p className="color-4">Total Donations</p>
            </div>
          </div> */}
          <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8 pl-0 ">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 pl-0 xs-pr-0">
              <div className="sd-upcoming-bookings">
                <img
                  className="sd-dashboard-icons"
                  src={sd_dashboard_upcoming}
                  width="50px"
                  height="50"
                />
                <div className="sd-upcoming-bookings-table">
                  <h3 className="sd-side-heading-2 fw600">Upcoming Bookings</h3>
                  <div className="clearfix">
                    <table>
                      {data.map((item, i) => {
                        return (
                          <tr>
                            <td width="100%" className="sd-booking-name flex-j">
                              <span>
                                <a
                                  onClick={() => {
                                    history?.push(
                                      `/${selectedLanguage}/devotee/print-a-ticket?id=${EncryptInformation(
                                        item?.ticketId
                                      )}&display=view`
                                    );
                                  }}
                                >
                                  {item.serviceName}
                                </a>
                                <span
                                  style={{
                                    fontWeight: "500",
                                    marginLeft: "3px",
                                    marginRight: "5px",
                                  }}
                                >
                                  ({item?.serviceType?.replace("_", " ")})
                                </span>
                              </span>
                              <span>
                                {IndiaTime(item.bookingDate).format(
                                  "ddd, Do MMMM  YYYY"
                                )}

                                {item?.serviceType === "online_darshanam" ||
                                item?.serviceType === "online_seva" ? (
                                  <>
                                    - (
                                    {moment(
                                      item.slotStartTime,
                                      "HH:mm:ss"
                                    ).format("hh:mm A")}{" "}
                                    -
                                    {moment(
                                      item.slotEndTime,
                                      "HH:mm:ss"
                                    ).format("hh:mm A")}
                                    )
                                  </>
                                ) : (
                                  <>
                                    {IndiaTime(item?.slotStartTime).format(
                                      "hh:mm A"
                                    ) ===
                                    IndiaTime(item?.slotEndTime).format(
                                      "hh:mm A"
                                    ) ? (
                                      <>
                                        {item.slotStartTime && ` - `}
                                        {item.slotStartTime &&
                                          IndiaTime(item.slotStartTime).format(
                                            "hh:mm A"
                                          )}
                                      </>
                                    ) : (
                                      <>
                                        {item.slotStartTime && ` - `}
                                        {item.slotStartTime &&
                                          IndiaTime(item.slotStartTime).format(
                                            "hh:mm A"
                                          )}
                                        -
                                        {item.slotEndTime &&
                                          IndiaTime(item.slotEndTime).format(
                                            "hh:mm A"
                                          )}
                                      </>
                                    )}
                                  </>
                                )}
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                      {data.length === 0 && (
                        <p className="flex-a mt-26 mb-20">
                          <img
                            src={sd_script}
                            alt="#"
                            height="30px"
                            style={{ marginRight: "5px" }}
                          />
                          Sorry! There seems to be no current bookings.
                        </p>
                      )}
                    </table>
                  </div>
                  {data.length !== 0 && (
                    <div className="clearfix mt-20 mb-20">
                      <a
                        className="sd-btn-orange"
                        onClick={() => {
                          history?.push(
                            `/${selectedLanguage}/devotee-app/booking-history`
                          );
                        }}
                      >
                        View All
                        <img
                          className="sd-arrow-btn ml-5"
                          src={SD_Right_Arrow_white}
                        />
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="sd-dashboard-banners ">
              <div className="col-xs-12 col-sm-6 col-md-12 col-lg-6 pl-0 xs-pr-0 mt-30">
                <div className="sd-four-sections sd-seva-banner">
                  <img
                    className="sd-dashboard-icons"
                    src={sd_dashboard_seva}
                    width="50px"
                    height="50"
                  />
                  <div className="sd-seva-right-para">
                    <h3 className="sd-side-heading-2 fw600 sd-border-no pb-15">
                      Sevas & Darshanam
                    </h3>
                    <div className="clearfix">
                      <p className="sd-booking-date sd-para-text">
                        Darshanam and Seva tickets can now be bought offline at
                        the ticket counter and online via our website. Relish by
                        visiting Sri Kanaka Durgamma Devasthanam{" "}
                      </p>
                    </div>
                    <div className="clearfix mt-10">
                      <a
                        className="sd-btn-blue"
                        onClick={() => {
                          history?.push(
                            `/${selectedLanguage}/sevas-and-darshanam`
                          );
                        }}
                      >
                        Book Now
                        <img
                          className="sd-arrow-btn ml-5"
                          src={SD_Right_Arrow_white}
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-12 col-lg-6 pl-0 xs-pr-0 mt-30">
                <div className="sd-four-sections sd-accommodation-banner">
                  <img
                    className="sd-dashboard-icons"
                    src={sd_dashboard_accomadation}
                    width="50px"
                    height="50"
                  />
                  <div className="sd-seva-right-para">
                    <h3 className="sd-side-heading-2 fw600 sd-border-no  pb-15">
                      Accommodation
                    </h3>
                    <div className="clearfix">
                      <p className="sd-booking-date sd-para-text">
                        Book comfortable and hygienic AC/Non AC Rooms, Cottages,
                        Guest Houses, Hotels, close to all pilgrimage spots,
                        with all basic amenities.
                      </p>
                    </div>
                    <div className="clearfix mt-10">
                      <a
                        className="sd-btn-orange"
                        onClick={() => {
                          history?.push(
                            `/${selectedLanguage}/devotee-app/online-booking/accommodations`
                          );
                        }}
                      >
                        Book Now
                        <img
                          className="sd-arrow-btn ml-5"
                          src={SD_Right_Arrow_white}
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-12 col-lg-6 pl-0 xs-pr-0 mt-30">
                <div className="sd-four-sections sd-donation-banner">
                  <img
                    className="sd-dashboard-icons"
                    src={sd_dashboard_donation}
                    width="50px"
                    height="50"
                  />
                  <div className="sd-seva-right-para">
                    <h3 className="sd-side-heading-2 fw600 sd-border-no  pb-15">
                      Donations
                    </h3>
                    <div className="clearfix">
                      <p className="sd-booking-date sd-para-text">
                        Devotees can now donate to Sri Durga Malleswara Swamy
                        Devasthanam through online payments. We accept credit
                        card,debit cards and UPI transactions.
                      </p>
                    </div>
                    <div className="clearfix mt-10">
                      <a
                        className="sd-btn-green"
                        onClick={() => {
                          history?.push(
                            `/${selectedLanguage}/devotee-app/online-booking/donations`
                          );
                        }}
                      >
                        Donate
                        <img
                          className="sd-arrow-btn ml-5"
                          src={SD_Right_Arrow_white}
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-12 col-lg-6 pl-0 xs-pr-0 mt-30">
                <div className="sd-four-sections sd-prabha-banner">
                  <img
                    className="sd-dashboard-icons"
                    src={sd_dashboard_srisaila_prabha}
                    width="50px"
                    height="50"
                  />
                  <div className="sd-seva-right-para">
                    <h3 className="sd-side-heading-2 fw600 sd-border-no  pb-15">
                      Publications
                    </h3>
                    <div className="clearfix">
                      <p className="sd-booking-date sd-para-text">
                        Durga Malleswara Swamy Varla Devasthanam has been
                        publishing a Religious Monthly Magazine regarding hindu
                        traditions.
                      </p>
                    </div>
                    <div className="clearfix mt-10">
                      <a
                        className="sd-btn-blue-dark"
                        onClick={() => {
                          history?.push(
                            `/${selectedLanguage}/devotee-app/online-booking/publications`
                          );
                        }}
                      >
                        Subscribe
                        <img
                          className="sd-arrow-btn ml-5"
                          src={sd_dashboard_side_arrow}
                          alt=">"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {currentImage && (
            <Lightbox
              small={currentImage}
              large={currentImage}
              onClose={() => setCurrentImage(null)}
            />
          )}
          <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 sd-dashboard-right-panel xs-pl-0 xs-pr-0 sm-pt-30 pr-0 pl-0">
            <div className="sd-upcoming-events">
              <h2 className="sd-side-heading fw500 sd-border-no">
                <span className="fw700">Upcoming</span> Events
              </h2>
              <Slider {...settings}>
                <div className="item" tabIndex="-2" data-index="-2">
                  {" "}
                  <img
                    src={popbanner}
                    style={{ cursor: "pointer" }}
                    alt="banner"
                    width="100%"
                    onClick={() => {
                      setCurrentImage(popbanner);
                    }}
                  />
                </div>
                {/* <div className="item" tabIndex="-1" data-index="-1">
                  {" "}
                  <img
                    src={sd_event3}
                    alt="banner"
                    width="100%"
                    onClick={() => {
                      history?.push(
                        `/${selectedLanguage}/devotee-app/online-booking/paroksha-seva/booking/nandeeshwara-swamy-vari-visesharchana`
                      );
                    }}
                  />
                </div>
                <div className="item" tabIndex="-3" data-index="-2">
                  <img
                    src={sd_event1}
                    alt="banner"
                    width="100%"
                    onClick={() => {
                      history?.push(
                        `/${selectedLanguage}/devotee-app/online-booking/paroksha-seva/booking/laksha-kumkumarchana`
                      );
                    }}
                  />
                </div>
                <div className="item" tabIndex="-2" data-index="-3">
                  {" "}
                  <img
                    src={sd_event2}
                    alt="banner"
                    width="100%"
                    onClick={() => {
                      history?.push(
                        `/${selectedLanguage}/devotee-app/online-booking/paroksha-seva/booking/sri-bayalu-veerabhadra-swamy-vari-visesharchana`
                      );
                    }}
                  />
                </div>{" "} */}
              </Slider>
              {/* <OwlCarousel
                className="owl-theme"
                loop
                items="1"
                mouseDrag={true}
                touchDrag={true}
                autoplay={true}
                autoplayTimeout={5000}
                lazyLoad={true} // nav
                // navElement={<div><img src={} alt=""/></div>}
                fallbackEasing={"swing"}
              >
                <div className="item">
                  {" "}
                  <img src={sd_dashboard_upcoming_banner} alt="banner" />
                </div>
                <div className="item">
                  {" "}
                  <img src={sd_dashboard_upcoming_banner} alt="banner" />
                </div>
                <div className="item">
                  {" "}
                  <img src={sd_dashboard_upcoming_banner} alt="banner" />
                </div>
              </OwlCarousel> */}
            </div>
            <div className="sd-news-updates mt-40">
              <h2 className="sd-side-heading fw500">
                <span className="fw700">News</span> Updates
              </h2>
              <div className="sd-news-list mt-20">
                <div className="item">
                  <img
                    className="sd-dashboard-icons"
                    src={sd_news_list_icon}
                    alt="news"
                  />
                  <div className="sd-news-para">
                    <p>
                      Dussera Celebrations will be started with great grandeur
                      from 15-10-2023
                    </p>
                    <span className="sd-news-date">15 Oct 2023</span>
                  </div>
                </div>
              </div>
              <div className="sd-news-list mt-20">
                <div className="item">
                  <img
                    className="sd-dashboard-icons"
                    src={sd_news_list_icon}
                    alt="news"
                  />
                  <div className="sd-news-para">
                    <p>
                      Sri Kanaka Durgamma Temple gearing for 10 day Dussera
                      festivities this year
                    </p>
                    <span className="sd-news-date">15 Oct 2023</span>
                  </div>
                </div>
              </div>
              <div className="clearfix mt-50">
                <a
                  className="sd-btn-orange"
                  onClick={() => {
                    history?.push(
                      `/${selectedLanguage}/media-room/press/temple-news`
                    );
                  }}
                >
                  View All
                  <img
                    className="sd-arrow-btn ml-5"
                    src={sd_dashboard_side_arrow}
                    alt=">"
                  />
                </a>
              </div>
            </div>
          </div>

          {modal && (
            <div className="sd-profile-popup">
              <div
                className="inner"
                style={{
                  height: "150px",
                  display: "flex",
                  flexDirection: "column",
                  minHeight: "150px",
                }}
              >
                <h2>
                  Update Email Address in My Account to recieve online tickets.{" "}
                  <span
                    style={{ textDecoration: "underline" }}
                    onClick={() => {
                      history.push(
                        `/${selectedLanguage}/devotee-app/my-account/profile`
                      );
                    }}
                  >
                    Click here
                  </span>{" "}
                </h2>
                <button
                  style={{
                    color: "white",
                    backgroundColor: "#991403",
                    marginTop: "10px",
                    alignSelf: "center",
                  }}
                  className="btn"
                  onClick={() => {
                    setModal(false);
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};
export default withRouter(Sd_dashboard_devotee_dashboard);
