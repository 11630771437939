import React from "react";
import "../../Utils/sd-styles/sd-misc.scss";
// import Sd_breadcrumb from "../../Components/sd-common-components/sd-breadcrumb";
import ParokshaSevaOne from "../../Components/body/paroksha-seva/sd-paroksha-seva-component-one";
import { SD_divider } from "../../Assets/imageList";
const Sd_terms_and_conditions = () => {
  return (
    <div>
      {/* <Sd_breadcrumb from="Terms and Conditions"></Sd_breadcrumb> */}
      <ParokshaSevaOne from="Terms and Conditions" />
      <div className="sd-t-and-c">
        <div className="sd-t-and-c-inner">
          {/* <h2></h2> */}
          <div className="top white-bc">
            <h1>Terms and Conditions</h1>
            <img
              src={SD_divider}
              alt={SD_divider}
              style={{ height: "20px" }}
            ></img>
          </div>
          <>
            <br />
            <span style={{ paddingLeft: "0px" }}>
              Last Updated: September 20, 2022
            </span>
            <br />
            <br />
            Thank you for using the online web portal of Sri Durga Malleswara
            Swamy varla Devasthanam! The user of the online web portal
            www.kanakadurgamma.org (hereinafter called the “Devotee/Devotees”)
            shall read and understand these terms and conditions before actively
            using the web portal of Sri Durga Malleswara Swamy varla
            Devasthanam!
            <br />
            <br /> <span>1. Your Acceptance:</span>By using or visiting the web
            portal www.kanakadurgamma.org and Sri Durga Malleswara Swamy varla
            Devasthanam! mobile application or any of Sri Durga Malleswara Swamy
            varla Devasthanam! mobile applications, products, software, data
            feeds, and services provided to you on, from, or through the
            www.kanakadurgamma.org website or application services (collectively
            the "Service") you signify your agreement to these terms and
            conditions (the Terms of Service). If you do not agree to any of
            these terms, please do not use the Service. Although we may attempt
            to notify you when major changes are made to these Terms of Service,
            you should periodically review the most up-to-date version available
            at www.kanakadurgamma.org Sri Durga Malleswara Swamy varla
            Devasthanam may, in its sole discretion, modify or revise these
            Terms of Service and policies at any time, and you agree to be bound
            by such modifications or revisions. Nothing in these Terms of
            Service shall be deemed to confer any third-party rights or benefits
            <br />
            <br />
            <span> 2. Service:</span>These Terms of Service apply to all
            Devotees using the Services of Sri Durga Malleswara Swamy varla
            Devasthanam, including the using the services online on
            website/mobile platforms. The Service includes all aspects of Sri
            Durga Malleswara Swamy varla Devasthanam, including but not limited
            to all purchase of Pooja & Daily Seva Tickets, Paroksha Seva
            Booking, Darshanam Tickets, Prasadam, Kalyana Katta tickets,
            Accommodation, AnnaPrasadam Tickets, Transportation Tickets, Kalyana
            Mandapam Booking and Tour Packages and services offered via the
            www.kanakadurgamma.org website or mobile application/s platforms to
            the Devotees.
            <br />
            <br />
            <span> 3. Eligibility:</span>Transaction on the
            www.kanakadurgamma.org website or mobile application/s platforms is
            available only to persons who can form legally binding contracts
            under Law. Persons who are "incompetent to contract" within the
            meaning of the respective law of the territory including
            un-discharged insolvents etc. are not eligible to use the Platform.
            If you are a minor (As per jurisdiction of your country)/under the
            age of 18 years, you may use the Platform or access content on the
            Platform only under the supervision and prior consent/ permission of
            a parent or legal guardian. As a minor if you wish to transact on
            the Platform, such transaction on the Platform may be made by your
            legal guardian or parents. www.kanakadurgamma.org reserves the right
            to refuse to provide you with services on the Platform if it is
            brought to Sri Durga Malleswara Swamy varla Devasthanam’s notice or
            if it is discovered that You are under the age of 18 years and
            transacting on thewww.kanakadurgamma.org website or mobile
            application/s platforms
            <br />
            <br />
            <span>4. Services:-</span>
            <br />
            <div className="ml-2 mt-3">
              <span>4.1 Purchase of Pooja & Daily Seva Tickets :-</span>
              Devotees can search for the availability and purchase Poojs &
              Daily Seva Tickets. For all Sevas & Pujas, Male sevadaris shall
              wear Pancha with Kanduva and females shall wear Saree with Blouse
              invariably. Other dresses will not be allowed. Sevadaris shall
              wear Sampradaya Vasthrams invariably. Only couples or single
              person will be allowed to perform on one ticket. Children below 10
              years of age are also permitted to follow their parents. Other
              kith and kin will not be allowed along with sevadars ticket
              holders. Devotees are requested to collect the abhishekam / puja
              articles inside the temple. Only single person will be allowed to
              perform seva on one ticket (Couples are not allowed on a Single
              seva ticket).
              <br />
              <br />
              <span>4.2 Purchase of Paroksha Seva Booking :-</span> Devotees who
              are unable to come to Vijayawada and perform Puja at Temple can
              witness the Puja/Homa from their respective location through Live
              in Youtube, Facebook, Twitter.
              <br />
              <br />
              <span>4.3 Purchase of Darshanam Tickets :-</span> Devotees can
              search for the availability and purchase the darshanam tickets –
              Devotees can purchase multiple tickets, however mandatorily
              required to provide all the Devotee Names, Gender, Age, ID proof
              such as Aadhar details for booking Online Darshanam ticket. All
              devotees in the group had to report together and produce original
              identity proof submitted while booking the tickets. The Devotees
              should wear traditional dresses only. Male: Dhoti, Shirt / Kurtha,
              Pyjama. Female: Saree / Half Saree / Chudidar with Dupatta while
              visiting the temple for Darshanam. Devotees are also required to
              bring a printed copy of the receipt of Darshanam Tickets. A
              Maximum delay of 1 ½ hour from reporting time is allowed
              thereafter the Darshanam Tickets become invalid. Devotees should
              not carry any luggage/cell phones/electronic gadgets while
              reporting for Darshanam. All bookings once receipt generated are
              FINAL: Postponement/advancement/cancellation/refund is not
              allowed. Entry with foot wear is not permitted into the queue
              lines, mada streets and Temple premises. Sri Durga Malleswara
              Swamy varla Devasthanam reserves the right to cancel any Darshanam
              under special circumstances and the same cannot be questioned by
              the Devotees. These tickets are non-transferable and cannot be
              canceled.
              <br />
              <br />
              <span> 4.4. Purchase of Accommodation :-</span> Devotees can
              search availability and purchase accommodation for A/C and Non-A/C
              rooms subject to availability.
              <br />
              <br />
              <span>
                4.5 Purchase of E-magazines, Subscription to News letters,
                Publications, Go-Products, CD’s & other products :-
              </span>{" "}
              Devotees can purchase E-magazines, Subscription to Newsletters,
              Publications, Go-Products, CD’s & other products. When devotees
              place an order on our Sri Durga Malleswara Swamy varla Devasthanam
              website or mobile Platform, the said order is considered an offer
              to Sri Durga Malleswara Swamy varla Devasthanam. The latter
              therefore reserves the right to refuse your order at any time for
              any reason, including the non-availability of services ordered or
              incorrectly described or if there is an error. If a devotee's
              order is accepted, you will receive a confirmation to that effect
              and a receipt will be generated. Otherwise, Devotees will receive
              a message stating that your order has been canceled or denied.
              Transactions which are successfully debited by the payment gateway
              but not confirmed or receipt generated are deemed to be failed
              transactions. All such failed transactions are eligible for refund
              post reconciliation by Sri Durga Malleswara Swamy varla
              Devasthanam. In case of such failed transactions, money is
              refunded in 10-15 working days.
            </div>
            <br />
            <br />
            <span> 5. Media Room:</span>The website/Mobile Platform exhibit the
            following, Media Kit, Photo Gallery, Video Gallery, Events
            Information including upcoming events, Publications, News, Press
            Releases, Press Coverage, Media Queries and Newsletter, Daily
            Updates, Temple Diary, e-Library (Publications & CDs), e-Tenders /
            Auctions, RTI ACT, Subscribe Newsletter link etc.
            <br />
            <br />
            <span>6. Get Involved:</span>Devotees can get involved in Volunteer
            Opportunities such as Seva at Temple, Anna Prasadam, Queue
            Management, Health, Go-seva, etc. Devotees can be influencers to
            take up volunteering. NGOs and corporate organizations can also
            participate under CSR activities.
            <br />
            <br />
            <span>7. Devotee Responsibilities and Assumption of Risk:</span>
            Devotee/s acknowledge that many activities carry inherent risks and
            agree that, to the maximum extent permitted by applicable law,
            Devotee/s assume the entire risk arising out of your access to and
            use of the Sri Durga Malleswara Swamy varla Devasthanam website and
            mobile Platform and any Content, including devotee’s participation
            in any Experience, use of any other Service/s.
            <br />
            <br />
            <span>8. Reporting Violations:</span>If Devotee believe that there
            is an imminent risk of harm to a person or property, Devotee should
            immediately contact Sri Durga Malleswara Swamy varla Devasthanam
            before approaching any other authorities. In addition, if Devotee
            believe that any devotee/other person violated our Standards and or
            terms and conditions, Devotee should report concerns to Sri Durga
            Malleswara Swamy varla Devasthanam at endow-eosdmsd@gov.in
            eo_vijayawada@yahoo.co.in eo@kanakadurgamma.org.
            <br />
            <br />
            <span> 9. Copyright Notice:</span>This website including the
            pictures, art work, software, 3D photograph etc., included in the
            media room and other places on the website or mobile platform are
            the copyright protected to the Sri Durga Malleswara Swamy varla
            Devasthanam. All rights are reserved. Sri Durga Malleswara Swamy
            varla Devasthanam respects devotee/s feedback. Any information,
            including suggestions, questions, comments, creative ideas,
            graphics, or other materials submitted to the Sri Durga Malleswara
            Swamy varla Devasthanam, shall be treated as non-confidential,
            unless otherwise stated. Devotee/s agree that Devotee/s will not
            send any copyrighted information to us. Physical reprints of
            material from the site are permitted as long as you reference the
            URL for each original web page and the page title. Website content
            is subject to change without notice and at the sole editorial
            discretion of the Sri Durga Malleswara Swamy varla Devasthanam.
            Other websites may not copy pages or articles. No artwork or images
            may be copied to another website without prior written permission.
            Sri Durga Malleswara Swamy varla Devasthanam reserves the right to
            have any website remove a link to Sri Durga Malleswara Swamy varla
            Devasthanam site for any reason whatsoever. Access to information on
            the site (www.srikanakadurgamma.org) does not in any manner
            constitute an offer to sell or a solicitation of any offer to buy
            any of the securities of Sri Durga Malleswara Swamy varla
            Devasthanam. The information on this site is not and is under no
            circumstances be construed as, an advertisement or a public offering
            of the securities of Sri Durga Malleswara Swamy varla Devasthanam.
            <br />
            <br />
            <span>10. General Obligations:</span>Devotes shall access Sri Durga
            Malleswara Swamy varla Devasthanam website/Mobile Platforms only for
            lawful purposes and Devotes shall be responsible for complying with
            all applicable laws, statutes and regulations in connection with the
            use of the website/ Mobile Platform. This Website is for Devote
            personal and non-commercial use. Devotees shall not modify, copy,
            distribute, transmit, display, perform, reproduce, publish, license,
            create derivative works from, transfer or sell any information,
            products or facilities obtained from this Website. Devote shall not
            create a hypertext link to the Website/Mobile Platform or "frame"
            the Website, except with the express advance written permission of
            Sri Durga Malleswara Swamy varla Devasthanam.
            <br />
            <br />
            <span>11. Devotees Must Keep Their Passwords Private:</span> 
            Devotees are required to keep their account passwords confidential
            and not share this information with others. Security of Devotee
            accounts will not be the responsibility of Sri Durga Malleswara
            Swamy varla Devasthanam in case password is not kept confidential by
            Devotee.
            <br />
            <br />
            <span>12. Corrections /Updating Personal Information:</span> 
            Devotees must provide accurate and correct contact information
            (name, postal address, e-mail address, phone number etc). All
            further communication from Sri Durga Malleswara Swamy varla
            Devasthanam to Devotees will be on the address provided. In case of
            incorrect information provided by Devotee, Sri Durga Malleswara
            Swamy varla Devasthanam will not be responsible for any failure of
            communication.
            <br />
            <br />
            <span>13. Use of the Online facilities: </span>Devotees may only use
            this facility to make legitimate bookings / reservations i.e.,
            Devotees may not use this facility to book facilities for the
            purpose of commercial resale and profit. Without limitation, any
            speculative, false or fraudulent reservation or any reservation in
            anticipation of demand is prohibited.
            <br />
            <br />
            <span>14. Devotees Responsibility:</span>Devotees confirm that they
            are the authorized holder of the credit card or the original account
            holder used in the transactions Devotees make using the
            website/platform and Devotees alone use the "id" and "password" that
            has been created in the website/mobile platform . Sri Durga
            Malleswara Swamy varla Devasthanam will not be responsible for any
            financial loss, inconvenience or mental agony resulting from misuse
            of ID/password/credit card number/account details number for booking
            facilities on this website/Mobile Platform.
            <br />
            <br />
            <span>15. Limitation of Liability: </span>Sri Durga Malleswara Swamy
            varla Devasthanam shall not be liable to devotees save as expressly
            provided for in these terms and conditions and shall have no other
            obligations, duties or liabilities whatsoever in contract, tort or
            otherwise arising out of the use of the facility or connection to
            the website/Mobile Platform. For online payment Sri Durga Malleswara
            Swamy varla Devasthanam as a merchant shall be under no liability
            whatsoever in respect of any loss or damage arising directly or
            indirectly out of the decline of authorization for any Transaction,
            on Account of the Cardholder having exceeded the preset limit.
            <br />
            <br />
            <span>16. Disclaimer:</span>The content of this website is for
            information purposes only. Every effort is made to ensure the site
            is up to date and accurate, no legal liability or other
            responsibility is accepted by or on behalf of the Sri Durga
            Malleswara Swamy varla Devasthanam for any errors, omissions, or
            statements on this site, or any site to which these pages connect.
            The Sri Durga Malleswara Swamy varla Devasthanam accepts no
            responsibility for any loss, damage or inconvenience caused as a
            result of reliance on such information. The Sri Durga Malleswara
            Swamy varla Devasthanam cannot control the content or take
            responsibility for pages maintained by external providers. Where we
            provide links to sites, we do not by doing so endorse any
            information or opinions appearing in them. We accept no liability
            whatsoever over the availability of linked pages. The Sri Durga
            Malleswara Swamy varla Devasthanam reserves the right to refuse the
            provision of links to any external content, for whatever reason
            deemed fit. Every effort is made to keep the website up and running
            smoothly. However, the Sri Durga Malleswara Swamy varla Devasthanam
            take no responsibility for, and will not be liable for, the website
            being temporarily unavailable due to technical or other issues. The
            website contents are subject to change..
            <br />
            <br />
            <span>17. Force Majeure:</span>Sri Durga Malleswara Swamy varla
            Devasthanam shall not be liable for any delay or failure to perform
            resulting from causes outside its reasonable control, including, but
            not limited to, acts of God, natural disasters, war, terrorism,
            riots, embargoes, acts of civil or military authorities, fire,
            floods, accidents, pandemics, epidemics or disease, strikes or
            shortages of transportation facilities, fuel, energy, labor or
            materials.
            <br />
            <br />
            <span>18. Emails and SMS:</span>Devotees will receive administrative
            communications from Sri Durga Malleswara Swamy varla Devasthanam
            using the email address or other contact information Devotees
            provide. Enrollment in additional email subscription programs will
            not affect the frequency of these administrative emails, though
            devotees should expect to receive additional emails specific to the
            program(s) to which devotees have opted. Devotees may also receive
            promotional emails from Sri Durga Malleswara Swamy varla
            Devasthanam.
            <br />
            <br />
            <span>19. Cautionary Notice:</span>This is to notify that Sri Durga
            Malleswara Swamy varla Devasthanam is a well-reputed shrine that
            offers devotional services to worshippers globally. We do not
            solicit or mislead the public at any stage of the services processes
            we offer nor have we authorized any agencies/partners to do the same
            on our behalf..
            <br />
            <br />
            If any potential service/business partner/Individual receives any
            unsolicited or fraudulent communication in the name of Sri Durga
            Malleswara Swamy varla Devasthanam for fraudulent payment of money
            to obtain special services or any fraudulent contact for other
            services, they are advised not to respond to the same. It has been
            brought to our notice that fake job offers in our administration
            panel and trust in the name of Sri Durga Malleswara Swamy varla
            Devasthanam have been circulated by some dishonest persons posing
            themselves as employees/representatives/agents of Sri Durga
            Malleswara Swamy varla Devasthanam with an ulterior motive to earn
            wrongful gain and/or cheat the public at large.
            <br />
            <br />
            Please be advised that anyone corresponding or transacting with such
            individuals and/or agencies in any manner will be doing so at their
            own risks and consequences. Sri Durga Malleswara Swamy varla
            Devasthanam strongly recommends you should not respond to such
            solicitations. In case you receive any such offer in the name of Sri
            Durga Malleswara Swamy varla Devasthanam, you are advised to reach
            out to us at(email) We will not be held responsible for any loss or
            damage suffered by such persons, directly or indirectly dealing with
            such fraudulent offers/advertisements(fake email IDs, fraudulent
            websites, telephone calls, SMS, Whatsapp communication, etc). Kindly
            note that Sri Durga Malleswara Swamy varla Devasthanam will not
            accept any liability/claims for any loss or damage that may be
            suffered or incurred directly or indirectly through correspondence
            with fraudulent individuals or agencies and that such communication
            should not be treated as an offer or representation from Sri Durga
            Malleswara Swamy varla Devasthanam.
            <br />
            <br />
            <span>20. Contact Us:</span>If you have any questions about these
            Terms and Conditions please email us at endow-eosdmsd@gov.in
            eo_vijayawada@yahoo.co.in eo@kanakadurgamma.org.
          </>
        </div>
      </div>
    </div>
  );
};
export default Sd_terms_and_conditions;
